.content123 {
    max-width: 1000px;
    width: 100%;
    margin: 0px 0 40px 0;
    padding-top: 100px;
    font-size: 14px;
    padding-left:200px;
    position: relative;
    font-family: 'Montserrat';

}

.content123 h1 {
    font-size: 18px;
    padding: 10px 0 15px 0;
    border-bottom: 1px solid rgb(14, 155, 202);
    margin: 0 0 30px 0;
}

@media only screen and (max-width: 1000px){
    .content123 {
        max-width: 1000px;
        width: 100%;
        margin: 0px 0 40px 0;
        padding-top: 100px;
        font-size: 14px;
        padding-left:20px;
        position: relative;
    }
    
    .content123 h1 {
        font-size: 18px;
        padding: 10px 0 15px 0;
        border-bottom: 1px solid rgb(14, 155, 202);
        margin: 0 0 30px 0;
    }
    }