.selectMenuAttachment {
    margin-left: 9.1%;
    width: 81.8%;
    padding-top: 0%;
    position: relative;
    /* border-left: 1px solid #ccccca;
    border-right: 1px solid #ccccca;  */
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color:  #ccccca;
    color: #2f3031;
    border: 0;
}
.selectMenuAttachment a{
    background-color:  #343a40;
    color: white;
    width: 33.33%;
    font-size: 18px;
    text-align: center;
    padding-top: 1.5%;
}

.selectMenuAttachment a:hover{
    background-color: #2f3031;
    color: white;
}

.selectMenuAttachment a:active{
    background-color: #2f3031;
}

.tabovi {
    background: #ccccca;
    height: 60px;
}



@media only screen and (max-width: 700px) {
    .selectMenuAttachment{
        float: center;
        justify-content: space-evenly;
        max-width: 100%;
        width: 110vh;
        margin: 0;
    }
    
    .selectMenuAttachment a{
        background-color:  #ccccca;
        color: rgba(0, 0, 0, 0.938);
        width: 33.33%;
        font-size: 14px;
        text-align: center;
        padding-top: 1.5%;
        margin-bottom: 20%;
    }
}