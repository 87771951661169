.attachment {
    background-image: url('/Background.png'); 
    background-attachment: fixed;

}
.attachment .headImg {
    width: 81.8%;
    margin-left: 9.1%;
    margin-top: 4.7%;
}
@media only screen and (max-width: 500px) {
    .attachment .headImg{
        margin: 10.2% 0 0 0;
        width: 100%;
    }
}