.services{
    background-color: white;
    letter-spacing: 0.03em;
    background-image: url('/Background.png');
    font-size:18px;
    font-family: 'Montserrat';
}
#logoServices {
    padding-top: 120px;  
    width: 20%;
    display: flex;
    flex-direction: row;
    margin-left: 40%;
    margin-right: 40%;
}
 .servicesText{
     text-align: center;
 }
 .servicesText2{
    margin-left: 25%;
}
 .servicesTitle{
    margin-top:40px;
    text-align: center;
    font-size:22px;
    font-weight: 600;
}
.twoDiv{
    display:flex;
    flex-direction: row;
    padding-bottom:40px;
}
.firstDiv{
    width:50%;
    margin-left:10%;
}
.secondDiv{
    width:50%;
    margin-left:10%;
    padding-top:50px;
}

@media only screen and (max-width: 720px) {
    #logoServices {
        padding-top: 100px;  
        width:40%;
        margin-left: 30%;
        margin-right: 30%;
     }
    .twoDiv{
        display:flex;
        flex-direction: column;
        padding-bottom:40px;
    }
    .firstDiv{
        width:100%;
        text-align: center;
        margin-left:0%;
    }
    .secondDiv{
        margin-left:20%;
        width:100%;
    }
    .servicesText{
        text-align: center;
    }
    .servicesText2{
       margin-left: 0%;
   }
    .servicesTitle{
       margin-top:40px;
       text-align: center;
       font-size:18px;
       font-weight: 550;
   }
}
