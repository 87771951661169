:root{
  --mainDark: ##343a40;
  --mainGrey: rgba(109,109,109);
  --mainLightGrey : rgba(172,172,172);
  --mainWhite: #fff;
  --mainBlue: #007bff;
}

.App {
  background-image: url('/Background.png');
  background-attachment: fixed;
  font-family: 'Montserrat';
}