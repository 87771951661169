#logoAboutUs {
   margin-top: 120px;
    
}
.aboutUs{
    align-items: center;
    text-align: center;
    background-color: white;
    letter-spacing: 0.03em;
    background-image: url('/Background.png');
    background-attachment: fixed;
    font-size:18px;
    font-family: 'Montserrat';

}
.aboutUsTitle {
    padding-top: 30px;
    font-weight: 900;
    font-family: 'Montserrat';
}

h4{
    margin-bottom: 0px;
    padding-bottom: 30px;
}

.aboutUsTitle #redLetter{
    color: rgb(212, 0, 0);
}
.aboutUsTitle #blueLetter{
    color: #0d8fe6;
}