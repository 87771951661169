.TechnicalInfo{
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    width: 81.8%;
}
.content{
    width: 100%;
    margin: 0 auto;
}
.node__content{
    padding: 30px 0;
}
.columns{
    display: flex;
}
.left-column{
    width: 50%;
    margin-bottom: 30px;
    margin-left: 3%;
}
#new {
    width: 100%;
    border-bottom: 1px solid #dbdbdb;
}
.label{
    width: 62%;
    margin-right: 0;
    font-weight: 700;
    float: left;
    font-size: 14px;
    line-height: 32px;
}
.spec{
    width: 38%;
    float: left;
    font-size: 14px;
    line-height: 32px;
}

.right-column{
    width: 45%;
    position: relative;
    padding: 0 0 0 10%;
}

.photoSpec1 img{
    width: 172%;
}

#dugme {
    margin-top: 10%;
    margin-left: 30%;
}
@media only screen and (max-width: 700px) {
    .TechnicalInfo{
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    .content{
        width: 100%;
        margin: 0 auto;
    }
    .node__content{
        padding: 30px 0;
    }
    .columns{
        display: block;
    }
    .left-column{
        width: 100%;
        margin-bottom: 30px;
        margin-left: 8%;
    }
  
    .row{
        width: 120%;
        border-bottom: 1px solid #dbdbdb;
        flex-direction: row;
    }
    .label{
        width: 60%;
        margin-right: 0;
        font-weight: 700;
        float: left;
        font-size: 14px;
        line-height: 32px;
    }
    .spec{
        width: 40%;
        font-size: 14px;
        line-height: 32px;
    }

    .naslov {
        text-align: center;
        margin-left: 20%;
    }
   
    .right-column{
        width: 100%;
        position: relative;
    }
    
    .photoSpec1 img{
        width: 120%;
        padding-left: 0;
        height:auto;
    }

    .photoSpec2 img{
        width: 90%;
        padding-left: 30%;
        padding-top: 5%;
        height:auto;
    }
}

