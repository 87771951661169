/* html {
  scroll-behavior: smooth;
} */
.NB_css {
    color: white;
    position: relative;
    z-index: 1000;  
    scroll-behavior: none;
}

.NB_css ul {
    margin: 0;
    padding: 0px;
    list-style: none;
}

.NB_css ul li {
    float: left;
    width: 20%;
    background-color: #343a40;
    font-size: 20px;
}

.NB_css ul li a{
    text-align: center;
    text-decoration: none;
    display: block;
    width: 100%;
}

.NB_css ul ul li a{
    text-align: left;
}
.NB_css ul ul li a #companyName{
    margin-left:20px;
}

.NB_css ul li a:link{
    color: white;
}

.NB_css ul li a:visited{
    color: white;
}

.NB_css ul li a:hover{
    background-color: #ccccca;
    color: black;
}

.NB_css ul li ul li{
    display: none;
    width: 140%;
    margin-left: -20%;
}

.NB_css ul li:hover ul li{
    display: block;
}

.machine_logo {
    width: 80px;
    height: auto;
    margin: 0;
    float: left;
}
.NB_response {
    display: none;
}
.tri_crte {
    display: none;
}

@media (max-width: 850px){    
    .NB_response {
        border: none;
        float: none;
        position: absolute;
        background: black;
        width: 100%;
        z-index: 1000;
        height: 700px;
        display: none;
        opacity: 0.92;
        margin-top: 5px;
    }
    .nsk {
        float: none;
    }
    #check {
        display: none;
    }
    .NB_response ul {
        display: block;
        margin-left: 5%;
        margin-top: 20px;
        list-style: none;
    }

    .NB_response ul li {
        padding-bottom: 5%;
    }
    .NB_response ul li ul {
        margin: 0 0 10px 15%;
        text-align: left;
    }

    .NB_response ul li ul li {
        width: 200px;
        padding-top: 23px;
    }

    .NB_response ul li ul li a {
        font-size: 18px;
        border-left: 2px solid #ccccca;
        padding-top: 5px;
        border-radius: 20%;
    }
    .NB_css {
        display: none;
    }
    .tri_crte {
        display: block;
    }
}