.attachment_middle {
    width: 100%;
    margin: 0 0 0 0;
    padding-top: 5%;
    color: black;
}

.btn_attachment {
    width: 31.3333%;
    margin: 0 1% 2% 1%;
    border: 0;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    background: none;

}

.saznaj_vise_al {
    opacity: 0;
    margin-top: 15px;
    width: 100%;
    height: 40px;
    background-color: #343a40;
    color: white;
    border: 0;
    padding-top: 1%;
}

.slika_al {
    width: 100%;
}

.btn_attachment:hover {
    transform: scale(1.06);
    transition: 0.5s;
}

.nameOfAttachment {
    color: black;
}

a {
    text-decoration: none;
}

.btn_attachment:hover .saznaj_vise_al {
    opacity: 1;
}

.btn_attachment:hover .saznaj_vise_al:hover {
    transform: scale(1.06);
    transition: 0.5s;
}

.machine_logo_al {
    width: 50px;
    float: left;
}

@media (max-width: 720px) {
    .btn_attachment {
        width: 94%;
        margin: 0 1% -10% 2%;
        padding-top:15%;
        border: 0;
        font-size: 16px;
        text-align: center;
        font-weight: bold;
        background: none;
    }
}