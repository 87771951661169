.footer {
  width: 100%;
  background-color: #343a40;
  border-top: 1px solid rgb(70, 171, 230);
  padding: 40px 0;
  position: relative;
  color:white;
  font-family: 'Montserrat';
  text-transform: uppercase;
}
.logo img{
  margin-left:-40px;
  width: 140%;
  height: auto;
}
.center {
  max-width: 1220px;
  margin: 0 auto;
  width: 100%;
}
.footer .copyright .copyright-links a::after {
  content: '-';
  margin: 0 10px;
}
.footer .links ul li a::after {
  content:'';
  margin: 0 0px
}
::placeholder {
  /* font-family: Arial, Helvetica, sans-serif; */
  font-size: 18px;
}
::placeholder {
  color: #666;
}
.footer .copyright .copyright-links a:last-child::after {
  content: '';
}
.footer .links {
  float: left;
  margin: 0 40px 0 0;
  text-align: center;
  
}
.footer .links h3 {
  font-weight: 700;
  font-size: 20px;
  margin: 0 0 20px 0;
  margin-left:15px;
}

#first{  
  margin-left:-55px;
}

.footer .links ul {
  list-style: none;
  line-height: 20px;
  margin-left:70px;
  text-align: left;
}
.footer .links ul li a {
  text-decoration: none;
  color:white;
  font-size: 15px;
}
.socialLogo{
  margin-left: 30px;
}
.footer .contact-social {
  float: none;
  text-align: center;
  
}
.footer .contact-social .social {
  margin: 20px 0 0 0;
  margin-left: 0px;
}
.footer .contact-social h4 {
  font-size: 18px;
  font-weight: 600;
}
.footer .contact-social .social ul {
  list-style: none;
  margin-right: 20px;
}
.footer .contact-social .social ul li {
  display: inline-block;
  margin: 5px 0 0 0;
  margin-left:-5px;
}
.footer .contact-social .social ul li a {
  display: block;
  width: 20px;
}
#cr{
  margin-left: -20px;
}
.clear {
  clear: both;
}
.footer .copyright {
  text-align: center;
  font-size: 11px;
  margin: 100px 0 0 0;
}
.footer .copyright a {
  text-decoration: none;
  color:white;
  font-size: 12px;
  font-weight: 700;
}
.footer .copyright .logo {
  max-width: 215px;
  margin: 20px auto;
}

body, div, span, applet, h1, h2, h3, h4, h5, h6, p, a, img, strong, sub, sup, center, dl, dt, dd, ol, ul, li, table, tr, th, td, label {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

  @media screen and (max-width: 1400px) {
  .center {
      max-width: 1024px;
  }
  .footer .links {
    float: left;
    margin: 0 10px 0 0;
    text-align: center;
  }
  .footer .contact-social .social ul {
    list-style: none;
    margin-left: 450px;
  }
}
  @media only screen and (max-width:1220px) {
  .center {
      max-width: 960px;
  }
  .footer .links ul {
    display: none;
  }
  .footer .links {
    text-align: center;
  }
}
  @media only screen and (max-width:1099px) {
  .center {
      max-width: 640px;
  }
  .footer .links {
    text-align: center;
  }
 }
  @media only screen and (max-width:720px) {
  .center {
      max-width: 420px;
      width: 100%;
  }
  .footer .links {
    display: none;
  }
  .footer .contact-social {
    margin: 0 auto;
    text-align: center;
    float: none;
  }
  .footer .contact-social img{
    margin-left:-550px;
  }
  .footer .contact-social .social ul li a {
    display: block;
    width: 32px;
  }

}
  @media only screen and (max-width:480px) {
  .center {
      max-width: 280px;
      width: 100%
  }
  .footer .links {
    display: none;
  }
  .footer .contact-social {
    margin: 0 auto;
    text-align: center;
    float: none;
  }
  .footer .contact-social img{
    margin-left:10px;
  }
  .footer .contact-social .social ul {
    list-style: none;
    margin-left: -30px;
  }
}